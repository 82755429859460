<template>
  <section class="page">
    <div class="info mb-16">
      <div class="info__hd">
        <div class="group">
          <div class="name">
            <span class="mr-8">播主分组：{{ groupDetail.name || '-' }}</span>
            <a-tooltip>
              <template slot="title">
                <div>编号：{{ groupDetail.group_code || '-' }}</div>
                <div>ID：{{ groupDetail.id || '-' }}</div>
              </template>
              <a-icon class="mr-8" type="info-circle" />
            </a-tooltip>
            <a-tag class="mr-8" color="blue">公共分组</a-tag>
            <a-dropdown v-if="groupDetail.isCreator === 1">
              <a-icon type="edit" theme="twoTone" />
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleRenameAuhtorGroup">重命名</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleDeleteAuthorGroup">删除该分组</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="creator">
            创建人：{{ groupDetail.user_oauth_name || '-' }}<span v-if="groupDetail.isCreator === 1">（本人）</span>
          </div>
        </div>
      </div>
      <div class="info__bd">
        <div class="data-item-wrap">
          <div class="data-item">
            <div class="data-item__label">媒体号</div>
            <div class="data-item__value">
              <router-link
                :to="{
                  name: 'authorList',
                  query: { authorGroupId: groupDetail.id, authorGroupName: groupDetail.name }
                }"
                target="_blank"
              >
                {{ groupDetail.dy_account_total + groupDetail.ks_account_total | humanReadable }}
              </router-link>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">同步经销商分组</div>
            <div class="data-item__value">
              <router-link
                v-if="groupDetail.sync_dealer_common_group_id"
                :to="{
                  name: 'dealerGroupDetail',
                  params: { groupId: groupDetail.sync_dealer_common_group_id }
                }"
                target="_blank"
              >
                {{ groupDetail.sync_dealer_common_group_name || '-' }}
              </router-link>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info__ft">
        <a-button class="mr-8" type="primary" @click="handleCopyAuthorGroup">
          复制
        </a-button>
        <a-button
          v-if="groupDetail.isCreator !== 1"
          class="mr-8"
          type="primary"
          :loading="followLoading"
          @click="handleFollowAuthorGroup"
        >
          {{ groupDetail.is_follow === 1 ? '取消关注' : '关注' }}
        </a-button>
        <a-button type="primary" @click="handleChangeAuthorGroup">
          公转私
        </a-button>
        <div class="update-time">更新时间：{{ groupDetail.mtime || '-' }}</div>
      </div>
    </div>

    <DataTable
      :loading="loading"
      :data="authorList"
      :pagination="pagination"
      :group-count-data="groupCountData"
      :export-loading="exportLoading"
      @pagination-change="handlePaginationChange"
      @export="handleExport"
      @operate="handleOperate"
    />

    <RenameGroupModal
      ref="renameGroupModal"
      v-model="renameAuthorGroupVisible"
      :form="renameAuthorGroupForm"
      :confirm-loading="renameAuthorGroupLoading"
      @confirm="handleRenameAuthorGroupConfirm"
    />

    <CopyAuthorGroupModal
      ref="copyAuthorGroupModal"
      v-model="copyAuthorGroupVisible"
      :form="copyAuthorGroupForm"
      :confirm-loading="copyAuthorGroupLoading"
      @confirm="handleCopyAuthorGroupConfirm"
    />

    <ChangeAuthorGroupModal
      ref="changeAuthorGroupModal"
      v-model="changeAuthorGroupVisible"
      :form="changeAuthorGroupForm"
      :confirm-loading="changeAuthorGroupLoading"
      @confirm="handleChangeAuthorGroupConfirm"
    />
  </section>
</template>

<script>
import DataTable from './components/DataTable'
import RenameGroupModal from '@/components/RenameGroupModal'
import CopyAuthorGroupModal from '@/components/CopyAuthorGroupModal'
import ChangeAuthorGroupModal from '@/components/ChangeAuthorGroupModal'
import authorApi from '@/api/author'

export default {
  name: 'AuthorGroupDetail',
  components: {
    DataTable,
    RenameGroupModal,
    CopyAuthorGroupModal,
    ChangeAuthorGroupModal
  },
  data() {
    return {
      groupDetail: {},
      groupCountData: {},
      loading: false,
      authorList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      exportLoading: false,
      renameAuthorGroupVisible: false,
      renameAuthorGroupForm: {
        groupName: ''
      },
      renameAuthorGroupLoading: false,
      copyAuthorGroupVisible: false,
      copyAuthorGroupForm: {
        groupId: '',
        groupName: '',
        groupType: '',
        groupCode: '',
        groupAuthorCount: '',
        groupAuthorList: [],
        newGroupType: '',
        newGroupName: ''
      },
      copyAuthorGroupLoading: false,
      followLoading: false,
      changeAuthorGroupVisible: false,
      changeAuthorGroupForm: {
        groupName: '',
        groupAuthorCount: '',
        groupAuthorList: [],
        newGroupType: '',
        newGroupName: ''
      },
      changeAuthorGroupLoading: false
    }
  },
  async mounted() {
    await this.fetchAuthorGroupDetail()
    this.fetchAuthorGroupAuthorList()
  },
  methods: {
    // 获取播主分组详情
    async fetchAuthorGroupDetail() {
      try {
        const params = {
          groupId: this.$route.params.groupId
        }
        const { code, data, message } = await authorApi.fetchAuthorGroupDetail(params)

        if (code === 200) {
          this.groupDetail = {
            ...data.groupData,
            isCreator: data.groupData.creator_id === this.$router.app.user.id ? 1 : 0
          }
          this.groupCountData = data.groupCountData || {}
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理分页改变
    handlePaginationChange(page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.fetchAuthorGroupAuthorList()
    },
    // 获取播主分组播主列表
    async fetchAuthorGroupAuthorList() {
      this.loading = true
      try {
        const { page, size } = this.pagination
        const params = {
          groupId: this.$route.params.groupId,
          page,
          size
        }
        const { code, data, message } = await authorApi.fetchAuthorGroupAuthorList(params)

        this.loading = false

        if (code === 200) {
          this.authorList = (data.list || []).map(item => {
            return {
              ...item,
              isCreator: this.groupDetail.creator_id === this.$router.app.user.id ? 1 : 0
            }
          })
          this.pagination.total = data.total
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true
      try {
        const params = {
          groupId: this.$route.params.groupId
        }
        await authorApi.exportAuthorGroupAuthorList(params)
        this.exportLoading = false
      } catch (e) {
        console.error(e)
        this.exportLoading = false
      }
    },
    // 处理表格操作
    handleOperate(type, record) {
      type === 'delete' && this.$confirm({
        title: '确要从分组中将该播主删除吗？',
        okText: '确认删除',
        onOk: async () => {
          this.deleteAuthorGroupAuthor(record)
        }
      })
    },
    // 删除播主分组下的播主
    async deleteAuthorGroupAuthor(record) {
      try {
        const params = {
          commonGroupId: this.$route.params.groupId,
          authorId: record.authorId
        }
        const { code, message } = await authorApi.deleteAuthorGroupAuthor(params)

        if (code === 200) {
          this.$message.success('播主从分组删除成功')
          await this.fetchAuthorGroupDetail()
          this.fetchAuthorGroupAuthorList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleRenameAuhtorGroup() {
      this.renameAuthorGroupVisible = true
    },
    handleRenameAuthorGroupConfirm() {
      this.renameAuthorGroup()
    },
    // 重命名播主分组
    async renameAuthorGroup() {
      this.renameAuthorGroupLoading = true
      try {
        const params = {
          groupId: this.groupDetail.id,
          groupName: this.renameAuthorGroupForm.groupName,
          groupType: 1
        }
        const { code, message } = await authorApi.renameAuthorGroup(params)

        this.renameAuthorGroupLoading = false

        if (code === 200) {
          this.$message.success('重命名播主分组成功')
          this.renameAuthorGroupVisible = false
          this.$refs.renameGroupModal.resetForm()
          this.fetchAuthorGroupDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.renameAuthorGroupLoading = false
      }
    },
    handleDeleteAuthorGroup() {
      this.$confirm({
        title: '该分组为公共分组，请谨慎删除！',
        okText: '确认删除',
        onOk: async () => {
          this.delAuthorGroup()
        }
      })
    },
    // 删除播主分组
    async delAuthorGroup() {
      try {
        const params = {
          groupId: this.groupDetail.id
        }
        const { code, message } = await authorApi.delAuthorGroup(params)

        if (code === 200) {
          this.$message.success('删除播主分组成功')
          this.$router.push({ name: 'authorGroupList' })
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理复制
    async handleCopyAuthorGroup() {
      this.copyAuthorGroupVisible = true
      try {
        const params = {
          groupId: this.groupDetail.id,
          type: 1
        }
        const { code, data, message } = await authorApi.copySourceAutorGroup(params)

        if (code === 200) {
          this.copyAuthorGroupForm.groupId = data.id
          this.copyAuthorGroupForm.groupName = data.groupName
          this.copyAuthorGroupForm.groupType = data.isCommon
          this.copyAuthorGroupForm.groupCode = data.groupCode
          this.copyAuthorGroupForm.groupAuthorCount = data.authorAccountCount
          this.copyAuthorGroupForm.groupAuthorList = data.authorList
          this.copyAuthorGroupForm.newGroupType = data.newGroupType
          this.copyAuthorGroupForm.newGroupName = data.newGroupName
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理复制确定
    async handleCopyAuthorGroupConfirm() {
      this.copyAuthorGroupLoading = true
      try {
        const { groupId, groupCode, newGroupType, newGroupName, groupAuthorList } = this.copyAuthorGroupForm
        const params = {
          groupList: [{
            groupId: groupId,
            groupCode: groupCode,
            groupType: newGroupType,
            groupName: newGroupName
          }],
          authorList: groupAuthorList
        }
        const { code, message } = await authorApi.copyAuthorGroup(params)

        this.copyAuthorGroupLoading = false

        if (code === 200) {
          this.$message.success('复制播主分组成功')
          this.copyAuthorGroupVisible = false
          this.$refs.copyAuthorGroupModal.resetForm()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.copyAuthorGroupLoading = false
      }
    },
    // 处理关注/取消关注
    handleFollowAuthorGroup() {
      if (this.groupDetail.is_follow === 1) {
        this.unfollowAuthorGroup()
      } else {
        this.followAuthorGroup()
      }
    },
    // 关注播主分组
    async followAuthorGroup() {
      this.followLoading = true
      try {
        const params = {
          groupId: this.groupDetail.id,
          groupName: this.groupDetail.name,
          groupType: 1
        }
        const { code, message } = await authorApi.followAuthorGroup(params)

        this.followLoading = false

        if (code === 200) {
          this.$message.success('关注播主分组成功')
          this.fetchAuthorGroupDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.followLoading = false
      }
    },
    // 取消关注播主分组
    async unfollowAuthorGroup() {
      this.followLoading = true
      try {
        const params = {
          groupId: this.groupDetail.id,
          groupName: this.groupDetail.name,
          groupType: 1
        }
        const { code, message } = await authorApi.unfollowAuthorGroup(params)

        this.followLoading = false

        if (code === 200) {
          this.$message.success('取消关注播主分组成功')
          this.fetchAuthorGroupDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.followLoading = false
      }
    },
    // 处理公转私
    async handleChangeAuthorGroup() {
      this.changeAuthorGroupVisible = true
      try {
        const params = {
          groupId: this.groupDetail.id,
          type: 2
        }
        const { code, data, message } = await authorApi.copySourceAutorGroup(params)

        if (code === 200) {
          this.changeAuthorGroupForm.groupName = data.groupName
          this.changeAuthorGroupForm.groupAuthorCount = data.authorAccountCount
          this.changeAuthorGroupForm.groupAuthorList = data.authorList
          this.changeAuthorGroupForm.newGroupType = data.newGroupType
          this.changeAuthorGroupForm.newGroupName = data.newGroupName
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理公转私确定
    async handleChangeAuthorGroupConfirm() {
      this.changeAuthorGroupLoading = true
      try {
        const { newGroupType, newGroupName, groupAuthorList } = this.changeAuthorGroupForm
        const params = {
          groupList: [{
            groupType: newGroupType,
            groupName: newGroupName,
            source: 1
          }],
          authorList: groupAuthorList
        }
        const { code, message } = await authorApi.changeAuthorGroup(params)

        this.changeAuthorGroupLoading = false

        if (code === 200) {
          this.$message.success('播主分组公转私成功')
          this.changeAuthorGroupVisible = false
          this.$refs.changeAuthorGroupModal.resetForm()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.changeAuthorGroupLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.info {
  display: flex;
  padding: 24px;
  height: 120px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  &__hd {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  &__bd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  &__ft {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    position: relative;
    height: 100%;
  }
}

.group {
  .name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  .creator {
    margin: 8px 0 0;
  }
}

.data-item-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .data-item {
    display: flex;
    flex-flow: column-reverse;
    margin: 0 24px;

    &__label {
      text-align: center;
    }

    &__value {
      font-size: 24px;
      text-align: center;
    }
  }
}

.update-time {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
