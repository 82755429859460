<template>
  <div class="data-table" ref="dataTable">
    <a-row class="action-bar" ref="actionBar" type="flex">
      <a-col class="action-bar-left" flex="1 0 auto">
        该分组含{{ groupCountData.principalTotal | humanReadable }}个主体，
        {{ groupCountData.dealerTotal | humanReadable }}个经销商，
        {{ groupCountData.authorTotal | humanReadable }}个媒体号，
        {{ groupCountData.p2ExceptionTotal | humanReadable }}个互动数据(P2)授权异常，
        {{ groupCountData.p1ExceptionTotal | humanReadable }}个直播数据(P1)授权异常，
        {{ groupCountData.p9ExceptionTotal | humanReadable }}个线索数据(P9)授权异常
      </a-col>
      <a-col class="action-bar-right" flex="1 0 auto">
        <a-button
          type="primary"
          :loading="exportLoading"
          @click="handleExport"
        >
          导出
        </a-button>
      </a-col>
    </a-row>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
      :scroll="scroll"
    >
      <div class="author-info" slot="authorInfo" slot-scope="text, record">
        <div class="author-info__hd">
          <a-avatar
            :src="record.avatar"
            :size="64"
            icon="user"
          />  
        </div>
        <div class="author-info__bd">
          <div>昵称：{{ record.nickname || '-' }}</div>
          <div>媒体号：{{ record.code }}</div>
        </div>
      </div>
      <div class="platform-info" slot="platformInfo" slot-scope="text, record">
        {{ record.platformType | platformFilter }}
      </div>
      <div class="auth-info" slot="authInfo" slot-scope="text, record">
        <div>互动数据(P2)：{{ record.p2OauthStatus | p2OauthStatusFilter }}</div>
        <div>直播数据(P1)：{{ record.p1OauthStatus | p1OauthStatusFilter }}</div>
        <div v-if="record.platformType == 1">线索数据(P9)：{{ record.p9OauthStatus | p9OauthStatusFilter }}</div>
      </div>
      <div class="dealer-info" slot="dealerInfo" slot-scope="text, record">
        <div>名称：{{ record.dealerName || '-' }}</div>
        <div>编码：{{ record.dealerStoreCode || '-' }}</div>
        <div>大区：{{ record.dealerAreaNames || '-' }}</div>
        <div>城市：{{ record.dealerCityName || '-' }}</div>
        <div>主体：{{ record.principalNames || '-' }}</div>
      </div>
      <div class="aweme-info" slot="awemeInfo" slot-scope="text, record">
        <div>作品数：{{ record.awemeCountTotal | humanReadable }}</div>
        <div>模板作品数：{{ record.moduleAwemeCount | humanReadable }}</div>
      </div>
      <div class="interact-info" slot="interactInfo" slot-scope="text, record">
        <div>粉丝数：{{ record.fansCountTotal | humanReadable }}</div>
        <div>点赞量：{{ record.likeCountTotal | humanReadable }}</div>
      </div>
      <div class="operate" slot="operate" slot-scope="text, record">
        <router-link
          :to="{
            name: 'authorAnalysis',
            params: { authorId: record.authorId },
            query: { platform: record.platformType }
          }"
          target="_blank"
        >
          查看详情
        </router-link>

        <template v-if="record.isCreator === 1">
          <a-divider type="vertical" />
          <a @click="handleOperate('delete', record)">从分组删除</a>
        </template>
      </div>
    </a-table>

    <BasePagination
      ref="basePagination"
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>  
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'
import formatUtil from '@/utils/FormatUtil'
import { platformFilter, p2OauthStatusFilter, p1OauthStatusFilter, p9OauthStatusFilter } from '../../../authorList/constant'

const columns = [
  {
    title: '播主昵称',
    key: 'authorInfo',
    width: 300,
    scopedSlots: { customRender: 'authorInfo' }
  },
  {
    title: '平台',
    key: 'platformInfo',
    width: 100,
    scopedSlots: { customRender: 'platformInfo' }
  },
  {
    title: '平台授权',
    key: 'authInfo',
    width: 200,
    scopedSlots: { customRender: 'authInfo' }
  },
  {
    title: '经销商信息',
    key: 'dealerInfo',
    width: 200,
    scopedSlots: { customRender: 'dealerInfo' }
  },
  {
    title: '作品统计',
    key: 'awemeInfo',
    width: 200,
    scopedSlots: { customRender: 'awemeInfo' }
  },
  {
    title: '直播场次',
    dataIndex: 'liveCount',
    width: 200,
    customRender: formatUtil.humanReadable
  },
  {
    title: '互动统计',
    key: 'interactInfo',
    width: 200,
    scopedSlots: { customRender: 'interactInfo' }
  },
  {
    title: '进组时间',
    dataIndex: 'joinGroupTime',
    width: 200
  },
  {
    title: '操作',
    key: 'operate',
    width: 180,
    fixed: 'right',
    scopedSlots: { customRender: 'operate' }
  }
]

export default {
  name: 'DataTable',
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        }
      }
    },
    groupCountData: {
      type: Object,
      default() {
        return {}
      }
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns,
      scroll: {}
    }
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll()
      })
    }
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size)
    },
    handleExport() {
      this.$emit('export')
    },
    handleOperate(type, record) {
      this.$emit('operate', type, record)
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight
      const width = this.$refs.dataTable.offsetWidth
      const height = this.$refs.dataTable.offsetHeight
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0)
      const scroll = {}

      if (width - 48 < columnsWidth) {
        scroll.x = columnsWidth
      }

      if (height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0
        const tableHeadHeight = document.querySelector('.ant-table-thead').offsetHeight
        const otherHeight  = actionBarHeight + tableHeadHeight + 190
        
        scroll.y = `calc(100vh - ${otherHeight}px)`
      }

      this.scroll = scroll
    }
  },
  filters: {
    platformFilter,
    p2OauthStatusFilter,
    p1OauthStatusFilter,
    p9OauthStatusFilter
  },
  mounted() {
    this.setTableScroll()
    this.$_resizeHandler = () => {
      this.setTableScroll()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .action-bar {
    display: flex;
    align-items: center;
    padding: 0 0 16px;

    &-left {
      flex: 1;
    }

    &-right {
      text-align: right;
    }
  }


  .author-info {
    display: flex;
    align-items: center;

    &__hd {
      margin-right: 8px;
    }
  }
}
</style>
